import http from './request'


// application/json
// multipart/form-data
export const getLogin = (params, callback) => { http.login('/phoneLogin', params).then(res => { callback(res) }) }
export const sendCode = (params, callback) => { http.login('/sendCode', params).then(res => { callback(res) }) }
export const getSts = (params, callback) => { http.get('/com/sts', params).then(res => { callback(res) }) }
export const getDict = (params, callback) => { http.get('/subject/dict', params).then(res => { callback(res) }) }
export const getCom = (params, callback) => { http.get('/com/region', params).then(res => { callback(res) }) }
export const addSubject = (params, callback) => { http.post('/subject/add', params).then(res => { callback(res) }) }
export const getPull = (params, callback) => { http.get('/people/dict', params).then(res => { callback(res) }) }
export const addPeople = (params, callback) => { http.post('/people/add', params).then(res => { callback(res) }) }
export const getList = (params, callback) => { http.get('/subject/list', params).then(res => { callback(res) }) }
export const getInfo = (params, callback) => { http.get('/getInfo', params).then(res => { callback(res) }) } //获取用户信息
export const savePhone = (params, callback) => { http.post('/savePhone', params).then(res => { callback(res) }) } //更换手机号
export const mylist = (params, callback) => { http.get('/people/mylist', params).then(res => { callback(res) }) } //我的数字人
export const maininfo = (params, callback) => { http.get('/subject/info', params).then(res => { callback(res) }) } //我的主体
export const comment_List = (params, callback) => { http.get('/comment/myList', params).then(res => { callback(res) }) } //我的留言
export const collect_List = (params, callback) => { http.get('/collect/myList', params).then(res => { callback(res) }) } //我的收藏
export const getPay = (params, callback) => { http.post('/pay/goPay', params,'multipart/form-data').then(res => { callback(res) }) } //支付
export const peopleInfo = (params, callback) => { http.get('/people/detail/'+params.orderNo, params).then(res => { callback(res) }) } //支付查询
export const peopleList = (params, callback) => { http.get('/people/list', params).then(res => { callback(res) }) } //数字人
export const getDetails = (params, callback) => { http.get('/people/'+params.id, params).then(res => { callback(res) }) } //数字人详情
export const addCollect = (params, callback) => { http.post('/collect/add', params,'multipart/form-data').then(res => { callback(res) }) } //收藏
export const coldePeople = (params, callback) => { http.del('/people/'+params.id, params).then(res => { callback(res) }) } //数字人详情
export const getMessage = (params, callback) => { http.get('/comment/list', params).then(res => { callback(res) }) } 
export const addComment = (params, callback) => { http.post('/comment/add', params,'multipart/form-data').then(res => { callback(res) }) } //提交留言
export const editPul = (params, callback) => { http.put('/subject/edit', params).then(res => { callback(res) }) } //主体更新
export const savePul = (params, callback) => { http.put('/people/save', params).then(res => { callback(res) }) } //主体更新


export const collect_verityNums = (params, callback) => { http.get('/people/verityNums', params).then(res => { callback(res) }) } //我的收藏
export const people_id = (params, callback) => { http.get('/people/'+params.id, ).then(res => { callback(res) }) } //数字人详情
export const people_del = (params, callback) => { http.del('/collect/del/'+params.id, ).then(res => { callback(res) }) } //数字人取消收藏
export const save = (params, callback) => { http.post('/save', params).then(res => { callback(res) }) } // 信息更改
export const post_Comment = (params, callback) => { http.put('/comment/read', params,'multipart/form-data').then(res => { callback(res) }) } //查看留言
export const comment_total = (params, callback) => { http.get('/comment/total', params).then(res => { callback(res) }) } //查看未读总数
export const sexList = (params, callback) => { http.get('/people/sexList', params).then(res => { callback(res) }) } //数据字典性别
export const comPage = (params, callback) => { http.get('/com/page', params).then(res => { callback(res) }) } //注册协议
export const beforehand = (params, callback) => { http.get('/com/makerInfo', params).then(res => { callback(res) }) } //预上传
// export const comCard = (params, callback) => { http.get('/com/card', params).then(res => { callback(res) }) } //证件照验证
export const comCard = (params, callback) => { http.post('/com/cardNew', params,'multipart/form-data').then(res => { callback(res) }) } //新增证件照验证

export const comFoot = (params, callback) => { http.get('/com/foot', params).then(res => { callback(res) }) } //网站底部信息

export const evidence = (params, callback) => { http.get('http://sipo.tailor.org.cn/api/evidence/data', params).then(res => { callback(res) }) } //预上传处理
export const evidenceContent = (params, callback) => { http.post('http://sipo.tailor.org.cn/api/evidence/content', params).then(res => { callback(res) }) } //预上传处理


export const com_link = (params, callback) => { http.get('/com/link', params).then(res => { callback(res) }) } //相关网站
export const com_foot = (params, callback) => { http.get('/com/foot', params).then(res => { callback(res) }) } //网站底部信息

export const platform = (params, callback) => { http.get('/platform', params).then(res => { callback(res) }) } //平台介绍
export const partners = (params, callback) => { http.get('/partners', params).then(res => { callback(res) }) } //战略伙伴
export const news_list = (params, callback) => { http.get('/news/list', params).then(res => { callback(res) }) } //新闻列表
export const news_detile = (params, callback) => { http.get('/news/'+params.id, params).then(res => { callback(res) }) } //新闻详情
export const contact = (params, callback) => { http.get('/contact', params).then(res => { callback(res) }) } //联系我们
export const homecasea = (params, callback) => { http.get('/cases', params).then(res => { callback(res) }) } //案例展示
export const casesImg = (params, callback) => { http.get('/casesImg', params).then(res => { callback(res) }) } //案例展示
export const homeIndex = (params, callback) => { http.get('/index', params).then(res => { callback(res) }) } //首页案例展示






