import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/homePage.vue'),
    meta: {
      title: '数字人存证|数字人知识产权|数字人版权|数字人授权-中国数字人知识产权存证保护平台'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      title: '数字人登录-中国数字人知识产权存证保护平台'
    }
  },
  {
    path: '/applyFor',
    name: 'applyFor',
    component: () => import('../views/applyFor.vue'),
    meta: {
      title: '存证备案- 中国数字人知识产权存证保护平台'
    }
  },
  {
    path: '/personal',
    name: 'personal',
    component: () => import('../views/ApplyFor/personal.vue')
  },
  {
    path: '/personalRecord',
    name: 'personalRecord',
    component: () => import('../views/ApplyFor/personalRecord.vue')
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('../views/process/payment.vue')
  },
  {
    path: '/accomplish',
    name: 'accomplish',
    component: () => import('../views/process/accomplish.vue')
  },
  {
    path: '/share',
    name: 'share',
    component: () => import('../views/share.vue'),

  },

  {
    path: '/datum',
    name: 'datum',
    component: () => import('../views/MemberCenter/datum.vue'),
    meta: {
      title: '会员中心-中国数字人知识产权存证保护平台'
    }
  },
  {
    path: '/falls',
    name: 'falls',
    component: () => import('../components/Falls.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/search.vue'),
    meta: {
      title: '数字人查询-中国数字人知识产权存证保护平台'
    }
  }, {
    path: '/details',
    name: 'details',
    component: () => import('../views/details.vue'),
    meta: {
      title: '数字人详情-中国数字人知识产权存证保护平台'
    }
  }, {
    path: '/video',
    name: 'video',
    component: () => import('../views/videoView.vue')
  }, {
    path: '/modify',
    name: 'modify',
    component: () => import('../views/apply_modify.vue')
  }, {
    path: '/exhibition',
    name: 'exhibition',
    component: () => import('../views/exhibition.vue'),
    meta: {
      title: '案例-中国数字人知识产权存证保护平台'
    }
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
