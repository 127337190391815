<template>
  <div id="app">
    <nav></nav>
    <router-view />
   
  </div>
</template>
<script>
export default {
  name: "AppView",
  created() {
    // console.log('路由信息',this.$router);
  },
  updated() {
    // var token = localStorage.getItem("token");
    // if (this.$route.name == "login") {
    //   console.log("登录");
    // } else {
    //   if (!token) {
    //     this.$message({
    //       showClose: true,
    //       message: "请登录",
    //       type: "error"
    //     });
    //     setTimeout(() => {
    //       this.$router.push({ name: "login" });
    //     }, 2000);
    //   }
    // }
  }
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  width: 100%;
  // max-width: 1920px;
  min-width: 1440px;
  min-height: 100vh;
  margin: auto;
  background-color: #ebf1fb;
}

</style>
