<template>
  <div class="login">
    <div class="login-title">
      <img @click="homeChange" src="../images/logo.png" alt />
    </div>
    <div class="login-box">
      <div class="login-box-picture">
        <img src="../images/login-img.png" alt />
      </div>
      <div class="login-box-frame">
        <div class="login-box-frame-title">
          账号登录
          <div class="login-box-frame-title-wire"></div>
        </div>
        <div class="login-box-frame-account">
          <img src="../images/account.png" alt />
          <input
            oninput="value=value.replace(/[^\d]/g,'')"
            v-model="phone"
            name
            placeholder="请输入手机号"
          />
        </div>
        <div class="login-box-frame-account">
          <img src="../images/password.png" alt />
          <div class="login-box-frame-account-password">
            <input oninput="value=value.replace(/[^\d]/g,'')" v-model="code" placeholder="请输入验证码" />
            <div class="login-box-frame-account-password-code">
              <p v-if="time===-1" @click="codeChange">获取验证码</p>
              <p v-else>{{time}} s后重发</p>
            </div>
          </div>
        </div>
        <div class="login-box-frame-but" @click="routerChange">立即登录</div>
        <div class="login-box-frame-text">
          <p>未注册手机验证后自动登录</p>
          <p>
            登录或注册即代表同意
            <span>
              <el-button type="text" @click="popShow=!popShow">用户协议</el-button>
            </span>
          </p>
        </div>
      </div>
    </div>
    <div class="login-bottom">
      <p>中国数字人知识产权存证保护平台</p>
    </div>
    <div class="pop" v-show="popShow">
      <div class="pop-box">
        <div class="pop-box-img">
          <img @click="popShow=!popShow" src="../images/close.png" alt />
        </div>
        <div class="pop-box-title">用户服务协议</div>
        <div class="pop-box-text">
          <p v-html="content"></p>
        </div>
        <div class="pop-box-but" @click="popShow=!popShow">知道了</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLogin, sendCode, comPage } from "../utils/api";
export default {
  name: "loginView",
  data() {
    return {
      popShow: false,
      time: -1,
      phone: "",
      code: "",
      content: ""
    };
  },
  created() {
    // 页面头部标题
    document.title = this.$route.meta.title;
    this.init();
  },
  methods: {
    init() {
      comPage({}, res => {
        if (res.code === 200) {
          this.content = res.data.content;
        }
      });
    },
    // 登录
    routerChange() {
      getLogin({ phone: this.phone, code: this.code }, res => {
        if (res.code === 200) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "success"
          });

          localStorage.setItem("token", res.token);
          // sessionStorage.setItem('token',res.token)
          localStorage.setItem("avatar", res.data.avatar);
          localStorage.setItem("nickname", res.data.nickname);
          localStorage.setItem("msgTotal", res.data.msgTotal);
          console.log("获取token", sessionStorage.getItem("token"));
          this.$router.push({
            name: "applyFor",
            params: { phone: res.data.phone }
          });
          // this.$router.push("/applyFor");
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error"
          });
        }
      });
    },
    // 获取验证码
    codeChange() {
      var verify = /^1[3456789]\d{9}$/;
      if (verify.test(this.phone)) {
        this.time = 60;
        var setTime = setInterval(() => {
          --this.time;
          // console.log(this.time);
          if (this.time < 0) {
            clearInterval(setTime);
          }
        }, 1000);
        sendCode({ phone: this.phone }, res => {
          // console.log(res);
          if (res.code === 200) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "success"
            });
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error"
            });
          }
        });
      } else {
        this.$message({
          showClose: true,
          message: "手机号有误",
          type: "warning"
        });
      }
    },
    homeChange() {
      this.$router.push({ name: "home" });
    }
  }
};
</script>
<style scoped lang="scss">
.login {
  width: 100%;
  height: 100vh;
  min-height: 900px;
  background: url("../images/login-bg.png") center;
  background-position: center;
  background-size: 100% 100%;
  // padding-top: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-repeat: no-repeat;
  position: relative;
  outline: hidden;
  &-title {
    width: 32%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    > img {
      width: 100%;
      // height: 77px;
      margin: auto;
      cursor: pointer;
    }
  }

  &-box {
    width: 100%;
    //  padding-top: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding-bottom: 152px;
    &-picture {
      > img {
        width: 489px;
        height: 476px;
      }
    }
    &-frame {
      width: 530px;
      height: 634px;
      background-color: #fff;
      border-radius: 12px;
      margin-left: 109px;
      padding: 83px 58px 0;

      &-title {
        padding-bottom: 30px;
        border-bottom: 1px solid #dddddd;
        position: relative;
        margin-bottom: 34px;
        &-wire {
          width: 96px;
          height: 3px;
          background-color: #3a7bd8;
          position: absolute;
          bottom: -2px;
        }
      }
      &-account {
        width: 100%;
        display: flex;
        align-items: center;
        padding-top: 44px;
        padding-bottom: 31px;
        border-bottom: 1px solid #dddddd;
        font-size: 18px;
        font-weight: 400;
        > img {
          width: 13px;
          height: 20px;
          margin-right: 20px;
        }
        > input {
          outline: none;
          border: none;
          font-size: 18px;
        }
        &-password {
          width: 100%;
          display: flex;
          justify-content: space-between;
          > input {
            outline: none;
            border: none;
            font-size: 18px;
          }
          &-code {
            width: 130px;
            color: #3a7bd8;
            padding-left: 13px;
            border-left: 1px solid #dddddd;
            cursor: pointer;
          }
        }
      }
      &-but {
        width: 414px;
        height: 66px;
        color: rgb(255, 255, 255);
        font-size: 20px;
        font-weight: 400;
        line-height: 66px;
        text-align: center;
        margin-top: 60px;
        cursor: pointer;
        box-shadow: 1px 1px 10px 0px rgba(12, 34, 65, 0.23);
        background: linear-gradient(135deg, #3b7ad8, #10a3e4);
      }
      &-text {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        margin-top: 62px;
        text-align: center;
        > p > span {
          color: #3a7bd8;
          cursor: pointer;
        }
      }
    }
  }
  &-bottom {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #999999;
  }
}
.pop {
  position: absolute;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  &-box {
    width: 1000px;
    height: 747px;
    background-color: #fff;
    &-img {
      width: 100%;
      overflow: hidden;
      display: flex;
      justify-content: flex-end;
      padding-top: 22px;
      padding-right: 22px;
      > img {
        width: 17px;
        height: 17px;
      }
    }
    &-title {
      width: 100%;
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      margin-top: 21px;
      margin-bottom: 25px;
    }
    &-text {
      height: 448px;
      padding: 0 59px 0 56px;
      font-weight: 400;
      font-size: 16px;
      color: #666666;
      line-height: 36px;
      overflow-y: auto;
    }
    &-but {
      width: 414px;
      height: 66px;
      margin: 59px auto 66px;
      box-shadow: 1px 1px 10px 0px rgba(12, 34, 65, 0.23);
      background: linear-gradient(135deg, #3b7ad8, #10a3e4);
      font-size: 20px;
      font-weight: 400;
      color: #fff;
      text-align: center;
      line-height: 60px;
    }
  }
}
:deep .el-message-box__content {
  //  width: 980px;
  color: red;
}
</style>